<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">Новый списания продукция</span>
      <el-button type="success" size="small" class="mr-1">Сохранить</el-button>
      <el-button type="primary" size="small" class="mr-1"
        >Сохранить закрыть</el-button
      >
      <el-button type="warning" icon="el-icon-close" size="small"
        >{{$t('message.close')}}</el-button
      >
    </header>

    <div class="body__modal">
      <el-form
        ref="form"
        :model="form"
        size="small"
        class="aticler_m stylekhanForm"
      >
        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="d-flex align-items-center">
                <div class="text_a mr-3 mb-3">Списания продукция №6 от</div>
                <el-form-item>
                  <el-date-picker
                    v-model="form.value1"
                    type="date"
                    :placeholder="$t('message.delivery_time')"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                  ></el-date-picker>
                </el-form-item>
                <el-button type="primary" class="ml-3 mb-4"
                  >Привязать документ</el-button
                >
              </div>
            </el-col>
            <!-- end  col -->
            <el-col :span="8" class="text-right">
              <span style="color: #6c757d !important">Владелец:</span>
              Azizbek Eshonaliyev
            </el-col>
          </el-row>
          <!-- end el-row -->

          <el-row :gutter="40">
            <el-col :span="8">
              <el-form-item label="Кому.">
                <el-select v-model="form.test6">
                  <el-option label="Zone one" value="shanghai"></el-option>
                  <el-option label="Zone two" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- end  col -->

            <el-col :span="8">
              <el-form-item label="Тип документа.">
                <el-select v-model="form.test6">
                  <el-option label="Zone one" value="shanghai"></el-option>
                  <el-option label="Zone two" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- end  col -->

            <el-col :span="8">
              <el-form-item label="Номер документа.">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  col -->
          </el-row>
          <!-- end el-row -->
        </el-card>

        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="24"></el-col>
            <!-- end  col -->
            <el-col :sm="12">
              <el-form-item class="mb-0">
                <el-select
                  v-model="form.test6"
                  placeholder="Добавить позицию — введите наименование, код, штрихкод или артикул"
                >
                  <el-option label="Zone one" value="shanghai"></el-option>
                  <el-option label="Zone two" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- end  col -->
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      status: false,
      provider: false,
      contract: false,
      form: {
        num: 1,
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleChange(value) {
      console.log(value);
    },
  },
};
</script>
